import { createRouter, createWebHashHistory, RouteLocationNormalized, RouteRecordRaw, createWebHistory } from 'vue-router'
import mapRule from './config'
import navigatorType from '@/utils/browser'

const isPc = navigatorType()
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      name: 'title',
      keepAlive: true
    },
    children: [
      {
        path: '/',
        name: 'Index',
        component: () => import('../pages/Index.vue'),
        meta: {
          hideBack: true,
          keepAlive: true,
          footActive: "first",
          title: "Reference Materials | Weiyel",
          content: {
            keywords: "Reference Materials,Product Customization,Weiyel",
            description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
          }
        },
      },
      {
        path: '/news',
        name: 'News',
        component: () => import('../pages/News.vue'),
        meta: {
          keepAlive: true,
          searchType: 'news',
          footActive: "first",
          title: "News | Reference Materials",
          content: {
            keywords: "Reference Materials News,food testing,environment testing,ore",
            description: "News Center. Covers Food Testing,Environment Testing,Ore,Clinical Chemical and Drug Component Analysis."
          }
        },
      },
      {
        path: '/newsdetail',
        name: 'NewsDetail',
        component: () => import('../pages/NewsDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'news',
          title: "News | Reference Materials",
          content: {
            keywords: "Reference Materials News,food testing,environment testing,ore",
            description: "News Center. Covers Food Testing,Environment Testing,Ore,Clinical Chemical and Drug Component Analysis."
          }
        },
      },
      {
        path: '/previewdetail',
        name: 'previewDetail',
        component: () => import('../pages/previewDetail.vue'),
        meta: {
          hideFooter: true,
          // searchType: 'news',
          title: "News | Reference Materials",
          content: {
            keywords: "Reference Materials News,food testing,environment testing,ore",
            description: "News Center. Covers Food Testing,Environment Testing,Ore,Clinical Chemical and Drug Component Analysis."
          }
        },
      },
      {
        path: '/forget',
        name: 'Forget',
        component: () => import('../pages/Forget.vue'),
        meta: {
          title: "Password change | Reference Materials | Weiyel",
          content: {
            keywords: "Reference Materials,Product Customization,Weiyel",
            description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
          }
        },
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../pages/About.vue'),
        meta: {
          footActive: "first",
          title: "Weiyel | Leading Producer | Reference Materials",
          content: {
            keywords: "Leading Producer,Reference Materials",
            description: "Weiyel is RM research and development producer and distributor, 24,000mf R&D base, worldwide warehouse; 15,000+ RM products; ISO9001.",
            }
        },
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('../pages/checkout.vue'),
        meta: {
          footActive: "first",
          title: "Weiyel | Leading Producer | Reference Materials",
          content: {
            keywords: "Leading Producer,Reference Materials",
            description: "Weiyel is RM research and development producer and distributor, 24,000mf R&D base, worldwide warehouse; 15,000+ RM products; ISO9001.",
          }
        },
      },
      {
        path: '/paysuccess',
        name: 'Paysuccess',
        component: () => import('../pages/paysuccess.vue'),
        meta: {
          footActive: "first",
          title: "Weiyel | Leading Producer | Reference Materials",
          content: {
            keywords: "Leading Producer,Reference Materials",
            description: "Weiyel is RM research and development producer and distributor, 24,000mf R&D base, worldwide warehouse; 15,000+ RM products; ISO9001.",
          }
        },
      },
      {
        path: '/productCategory',
        name: 'ProductCategory',
        meta: {
          footActive: "second",
          title: "Catalogue of Reference Materials | Reference Materials | Weiyel",
          content: {
            keywords: "Leading Producer,Reference Materials,Weiyel",
            description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
          }
        },
        component: () => import('../pages/ProductCategory.vue'),
      },
      {
        path: '/product',
        name: 'product',
        meta: {
          name: 'product',
          keepAlive: true,
          searchType: 'product',
          footActive: "second",
          title: "Reference Materials | Weiyel",
          content: {
            keywords: "Leading Producer,Reference Materials,Weiyel",
            description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
          }
        },
        component: () => import('../pages/Product.vue'),
      },
      {
        path: '/:gid',
        name: 'productdetail',
        component: () => import('../pages/ProductDetail.vue'),
        meta: {
          hideFooter: true,
          searchType: 'product',
          title: "Reference Materials | Weiyel",
          content: {
            keywords: "Leading Producer,Reference Materials,Weiyel",
            description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
          }
        }
      },
      {
        path: '/customize',
        name: 'customize',
        meta: {
          name: 'customize',
          hideFooter: true,
          searchType: 'product',
          title: "Product Customization | Weiyel",
          content: {
            keywords: "Product Customization,Leading Producer,Reference Materials,Weiyel",
            description: "Weiyel is RM research and development producer and distributor, 24,000mf R&D base, worldwide warehouse; 15,000+ RM products; ISO9001.",
          }
        },
        component: () => import('../pages/Customize.vue')
      },
      {
        path: '/order',
        name: 'Order',
        component: () => import('../pages/Order.vue'),
        meta: {
          footActive: "third",
          // title:"订单管理-伟业计量-国家标准物质网",
          title: "Orders | Weiyel",
          content: {
            keywords: "Orders",
            description: "Orders",
          }
        }
      },
      {
        path: '/user',
        name: 'UserInfo',
        component: () => import('../pages/UserInfo.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          title: "Account Centers | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/userEdit',
        name: 'UserInfoEdit',
        component: () => import('../pages/UserInfoEdit.vue'),
        meta: {
          hideFooter: true,
          requireAuth: true,
          title: "Account Centers | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/foot',
        name: 'FootPrint',
        component: () => import('../pages/FootPrint.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          title: "My Tracks | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('../pages/Account.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          // title:"我的账户-伟业计量-国家标准物质中心",
          title: "My Account | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/message',
        name: 'Message',
        component: () => import('../pages/Message.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          title: "My Message | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/changeMobile',
        name: 'ChangeMobile',
        component: () => import('../pages/ChangeMobile.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          title: "Change Mobile | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/changePwd',
        name: 'ChangePwd',
        component: () => import('../pages/ChangePwd.vue'),
        meta: {
          footActive: "third",
          hideFooter: true,
          title: "Change Password | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/suggest',
        name: 'Suggest',
        component: () => import('../pages/Suggest.vue'),
        meta: {
          footActive: "third",
          requireAuth: true,
          title: "Change Suggest | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/shopCar',
        name: 'ShopCar',
        component: () => import('../pages/ShopCar.vue'),
        meta: {
          // footActive: "third",
          // title:"购物车-伟业计量-国家标准物质中心",
          footActive: "four",
          title: "Shopping Cart | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/submitOrder',
        name: 'SubmitOrder',
        component: () => import('../pages/SubmitOrder.vue'),
        meta: {
          Authentication: true,  // 需要登录认证
          hideFooter: true,
          title: "Submit Order | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },

      {
        path: '/openSearch',
        name: 'OpenSearch',
        component: () => import('../pages/OpenSearch.vue'),
        meta: {
          footActive: "third",
          title: "Search | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/address',
        name: 'Address',
        component: () => import('../pages/Address.vue'),
        meta: {
          hideFooter: true,
          Authentication: true,
          footActive: "third",
          requireAuth: true,
          title: "Address | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/addAddress',
        name: 'addAddress',
        component: () => import('../pages/AddAddress.vue'),
        meta: {
          hideFooter: true,
          footActive: "third",
          requireAuth: true,
          title: "Address | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/orderDetail',
        name: 'OrderDetail',
        component: () => import('../pages/OrderDetail.vue'),
        meta: {
          title: "OrderDetail | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('../pages/Login.vue'),
        meta: {
          hideFooter: true,
          title: "Login | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/contact',
        name: 'Contact',
        component: () => import('../pages/Contact.vue'),
        meta: {
          hideFooter: true,
          title: "Contact | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/initTel',
        name: 'InitTel',
        component: () => import('../pages/InitTel.vue'),
        meta: {
          hideFooter: true,
          title: "InitTel | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      {
        path: '/setPassword',
        name: 'SetPassword',
        component: () => import('../pages/SetPassword.vue'),
        meta: {
          hideFooter: true,
          title: "Set Password | Weiyel",
          content: {
            keywords: "Account Centers",
            description: "Account Centers",
          }
        }
      },
      // ===================================================================
    ]
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine.vue'),
    meta: {
      Authentication: true,  // 需要登录认证
      footActive: "third",
      requireAuth: true,
      // title:"会员中心-伟业计量-国家标准物质中心",
      title: "Account Centers | Weiyel",
      content: {
        keywords: "Leading Producer,Reference Materials,Weiyel",
        description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
      }
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue'),
    meta: {
      footActive: "first",
      // title:"搜索界面-伟业计量-国家标准物质中心",
      title: "Search | Weiyel",
      content: {
        keywords: "Leading Producer,Reference Materials,Weiyel",
        description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
      }
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/Error.vue'),
    meta: {
      footActive: "first",
      // title: "404界面-伟业计量-国家标准物质中心",
      title: "404 | Weiyel",
      content: {
        keywords: "Leading Producer,Reference Materials,Weiyel",
        description: "15000 CRMS in 13 classes,Goods in Stock,Fast delivery,Product Customization,One-stop Purchasing.",
      }
    }
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('../testPage/Index.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/testKd',
    name: 'TestKd',
    component: () => import('../testPage/TestKd.vue'),
    meta: {
      footActive: "first",
      title: "测试页面-伟业计量-国家标准物质中心",
    }
  },
  {
    path: '/maintain',
    name: 'Maintain',
    component: () => import('../maintenance/Maintain.vue'),
    meta: {
      footActive: "first",
      // title: "系统维护中",
      title: "System maintaining",
    }
  },
]

function scrollBehavior(to: any, from: any, savedPosition: any) {
  if (savedPosition) {
    return savedPosition
  } else {
    return {
      x: 0,
      y: 0
    }
  }
}


const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior
})

//  映射路由到pc端
function routerMap(to: RouteLocationNormalized) {
  const name = to.name;
  let path = mapRule[(name as string)];
  const idReg = /\{\{(.*?)\}\}/;
  if (isPc && path) {
    while (idReg.test(path)) {
      const argName = RegExp.$1;
      const id = to.query[argName] || to.params[argName]
      path = path.replace(idReg, id)
    }
    window.location.href = path
  }
}

router.beforeEach((to, from, next) => {
  routerMap(to)
  const href = window.location.href;
  const codeReg = /code=(.*?)&/;
  let code
  if( codeReg.test(href) ){
    code = RegExp.$1
  }
  const token = localStorage.getItem('token') || '';
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.meta.content) {
    const head = document.getElementsByTagName('head');
    const meta = document.createElement('meta');
    const keywords = document.querySelector('meta[name="keywords"]');
    const description = document.querySelector('meta[name="description"]')
    keywords && keywords.setAttribute('content', to.meta.content.keywords)
    description && description.setAttribute('content', to.meta.content.description)
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  const urlReg = /\/#(.*)/ig;
  if (href.match(urlReg)) {
    const urlPath = RegExp.$1;
    window.location.href = urlPath;
    return false
  }
  if (to.path === '/login') {
    if (token && to.query && to.query.redirect) {
      return next(to.query.redirect as string)
    } else {
      return next()
    }
  } else {
    if (to.matched.length === 0) {
      next("/error")
    }
  }

  if (!to.meta.requireAuth) {
    next()
  } else {
    if (token) {
      if (to.matched.length === 0) {
        next("/error")
      }
      next()
    } else {
      if (to.matched.length === 0) {
        next("/error")
      } else if (code) {
        next()
      } else {
        next({
          path: "login",
          query: {
            redirect: to.fullPath
          }
        })
      }
    }
  }
})





export default router
